import { GlobalProvider } from "../src/components/GlobalProvider";
import Every from "./components/Every";
import FooterWithSitemap from "./components/Footer";

function App() {
  return (
    <GlobalProvider>
      <Every />
      <FooterWithSitemap />
    </GlobalProvider>
  );
}

export default App;
