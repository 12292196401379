function Container({ PropComponent, heading, text }) {
  return (
    <div>
      <div
        className="" /*className="w-full sm:max-w-screen-sm md:max-w-screen-md lg:max-w-screen-lg xl:max-w-screen-xl p-4"*/
      >
        <div
          className="container horizontal" /*className="bg-slate-200 rounded-lg max-w-lg p-4"*/
        >
          {heading && (
            <h1 className="text-red-900 font-bold text-center text-2xl lg:text-3xl">
              {heading}
            </h1>
          )}
          {text && (
            <p className="text-center pb-4 text-xs font-bold text-gray">
              {text}
            </p>
          )}
          <div /*className="text-center"*/>
            <PropComponent />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Container;
