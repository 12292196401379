import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import TopPage from './TopPage';
import Pension from './calculator/Calculator';
import Budget from './Budget';
import startBudget from './budget/startBudget';


function Every() {
    return(
        <Router>
        <Routes>
            <Route exact path='/' Component={TopPage} />
            <Route exact path='/start' Component={Pension} />
            <Route exact path='/budget' Component={Budget} />
            <Route exact path='/startBudget' Component={startBudget} />
        </Routes>
        </Router>
    );
}
export default Every;
