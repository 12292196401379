import { Link } from 'react-router-dom';

const Main = () => {
    return (
        <div className="font-display h-screen max-w-screen-xl mx-auto w-4/5 mt-28">
            <h1 className="text-start leading-tight font-bold py-4 gap-y-4 font-display text-2xl md:text-6xl md:py-8 md:gap-y-8">Pension Budget Planner</h1>
            <h3 className="text-start whitespace-pre-wrap font-medium leading-snug font-display text-md md:text-3xl py-2 gap-y-2 md:py-4 md:gap-y-4">Learn how much you can save as your pension</h3>
            <h6 className="text-start whitespace-pre-wrap font-light line-clamp-5 font-display text-sm md:text-lg py-2 gap-y-2 md:py-4 md:gap-y-4">Our free Budget Planner puts you in control of your household spending and analyses your results to help you take control of your money. It’s already helped hundreds of thousands of people.</h6>

            <Link to="/startBudget">
                <button className="bg-red-900 text-white px-6 md:px-8 py-1 md:py-2 my-10 text-sm md:text-lg font-display font-light md:font-medium">
                    Get Started
                </button>
            </Link>

            <h3 className="text-start leading-snug font-medium font-display text-lg md:text-xl py-2 gap-y-2 md:py-4 md:gap-y-4">How to use our FREE Pension Budget Planner</h3>
            <h6 className="text-start whitespace-pre-wrap font-light line-clamp-5 font-display text-sm md:text-lg py-2 gap-y-2 md:py-4 md:gap-y-4">Before you get started, grab as much information (bank statements, bills…) as you can. The more up to date your details are, the more accurate your results will be.</h6>

        </div>
    );
}

export default Main;