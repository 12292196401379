
function Main() {
  return (
    <div className="font-display px-4 py-0 bg-lightblue">
      <div className="font-display max-w-6xl mx-auto flex flex-col md:flex-row">
        <div className="font-display w-full md:w-1/3 md:mr-8 mb-8 md:mb-0">
          <h2 className="font-display text-3xl md:text-4xl font-extrabold leading-9">
            How to use the calculator
          </h2>
        </div>

        <div className="font-display w-full md:w-2/3">
          <dl className="text-base md:text-lg">
            <dt className="mb-4">
              <h3 className="text-lg md:text-xl font-semibold">
                Kindly follow the steps below
              </h3>
            </dt>
            <dd className="mb-8 md:mb-16">
            <ol className="list-decimal pl-4">
                <li className="mb-2">
                Enter your details (gender, current age, and desired retirement age).
                </li>
                <li className="mb-2">
                State your current monthly income.
                </li>
                <li className="mb-2">
                State your current monthly pension contribution.
                </li>
                <li className="mb-2">
                Enter your Retirement Savings Account balance and your Voluntary Contribution balance.
                </li>
                <li className="mb-2">
                Enter your desired lumpsum amount at retirement.
                </li>
                <li className="mb-2">
                Enter your desired monthly payout at retirement.
                </li>
            </ol>
            </dd>
            {/* Add more FAQ items */}
          </dl>
        </div>
      </div>
    </div>
  );
}

export default Main;
