import React, { useState, useContext, useEffect } from "react";
import GlobalContext, { SET_FORM_VALUES } from "../GlobalProvider";
import { Slider } from "@material-tailwind/react";
import FormatMoney, { formatSalary } from "./FormatMoney";

function StepThree({ currentStep, setCurrentStep }) {
  const { state, dispatch } = useContext(GlobalContext);
  const { formValues } = state;

  const handleMonthlyChange = (e) => {
    const value = parseFloat(e.target.value);
    setMonthly(value);
    dispatch({ type: SET_FORM_VALUES, payload: { monthly: value } });
  };

  const handleMonthlyTextInputChange = (e) => {
    const value = parseFloat(e.target.value);
    dispatch({
      type: SET_FORM_VALUES,
      payload: { monthly: value },
    });
  };

  const [monthly, setMonthly] = useState(formValues.monthly);

  // Scroll to top when component mounts
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div className="text-center font-display mb-2 text-zinc-700 font-bold text-sm">
        <p>What is your estimated monthly pension contribution?</p>
      </div>
      <div className="flex flex-row justify-between items-center gap-2 ">
        <div className="w-1/12 font-semibold ">
          <p>₦0</p>
        </div>
        <div className="w-11/12">
          <Slider
            className="flex"
            color="red"
            size="sm"
            min={0}
            step={1}
            max={10000000}
            defaultValue={formValues.monthly}
            value={formValues.monthly}
            onChange={handleMonthlyChange}
          />
        </div>
        <div className="w-1/12">
          <p>₦10m</p>
        </div>
      </div>
      <div className="text-center ms-4 mt-4 mb-4">
        <input
          type="text"
          placeholder={formatSalary(formValues.monthly)}
          defaultValue={formValues.monthly}
          className="text-center text-red-900 font-semibold h-12 bg-gray-300"
          onChange={handleMonthlyTextInputChange}
          value={formValues.monthly}
        />
      </div>
    </div>
  );
}

export default StepThree;
