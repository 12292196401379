import Container from "../calculator/Container";
import { BudgetProvider } from "./BudgetProvider";
import Stepper1 from "./Stepper1";
const startBudget = () => {
    return(
        <div className="">
            <BudgetProvider>
                <Stepper1 />
            </BudgetProvider>
        </div>
    );
}

export default startBudget;