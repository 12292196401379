import logo from '../public/logo.png';
function Header() {
  return (
    <header className="font-display bg-white text-red-900 border-b shadow-xl border-red-900 flex flex-col lg:flex-row justify-between items-center pb-2 m-0 px-4 fixed w-full top-0 z-10">
      <a href="https://crusaderpensions.com/"><div className="cursor-pointer font-display font-extralight text-lg sm:text-md md:text-lg lg:text-xl xl:text-2xl pt-2 text-center lg:text-left">
        <img src={logo} height={80} width={150} alt="Logo" />
      </div>
      </a>
      <div className="space-x-4 ml-4 lg:ml-0 lg:space-x-6 flex flex-wrap lg:flex-nowrap">
        <p className="text-xs sm:text-base">
          <a className="" href="tel:+1234567890">+234 201 2713800-4</a>
        </p>
        <p className="text-xs sm:text-base">
          <a className="" href="mailto:info@crusaderpensions.com">info@crusaderpensions.com</a>
        </p>
        
      </div>
    </header>
  );
}

export default Header;