import React, { useState, useEffect } from 'react';
import { useBudgetContext } from '../budget/BudgetProvider';
import { formatSalary } from '../calculator/FormatMoney';


const InputCard = ({totals}) => {
  

  return (
    <div className="font-display text-gray-500 bg-transparent font-light border rounded-md w-[16rem] px-1 py-1 border-gray-500">
      {Object.entries(totals).map(([label, value], index) => (
        <div key={label} className={`flex justify-between ${index === Object.keys(totals).length - 1 ? '' : 'mb-1'}`}>
          <div className="font-light">{label}</div>
          <div>{value}</div>
        </div>
      ))}
      
    </div>
  );
};

const FinanceCard = () => {
  const { state } = useBudgetContext();
  const { incomeValues, houseHoldValues, financeValues, livingCostValues, familyFriendsValues, travelTransportValues } = state;
  let [expense, setExpense] = useState(0);
  
  const calculateTotal = (categoryValues) => {
    return Object.values(categoryValues).reduce((acc, value) => acc + parseFloat(value || 0), 0);
  };

  
  const income =  calculateTotal(incomeValues)

  const [totals, setTotals] = useState({
    'Household Bills': calculateTotal(houseHoldValues),
    'Finance & Insurance': calculateTotal(financeValues),
    'Living Cost': calculateTotal(livingCostValues),
    'Family & Friends': calculateTotal(familyFriendsValues),
    'Transportation': calculateTotal(travelTransportValues),
  });


  useEffect(() => {
    setExpense(calculateTotal(totals));
    totals.Transportation = calculateTotal(travelTransportValues);
  }, [houseHoldValues, financeValues, livingCostValues, familyFriendsValues, travelTransportValues]);
  
  const expensesFormatted =  formatSalary(expense);
  const pension =  income - expense;
  let pensionFormatted = (pension >= 0)&&(expense <= income) ? formatSalary(pension) : 'N/A';
  // let pensionFormatted = preExpense;

  return (
    <div className="font-display finance-card bg-white px-3 md:px-8">
      <div className="flex justify-between mb-4 px-1">
        <div className="font-bold text-black ">Monthly Income:</div>
        <div>{formatSalary(income)}</div>
      </div>
      <div className="flex justify-between mb-4">
        <InputCard totals={totals} />
      </div>
      <div className="flex justify-between mb-4 px-1">
        <div className="font-bold text-black">Monthly Expenses:</div>
        <div>{expensesFormatted}</div>
      </div>
      <div className=" font-display flex flex-col">
        <div className="font-bold text-black border border-gray-300 bg-gray-100">Disposable Income:</div>
        <div className='font-bold bg-red-900 rounded-b-md md:rounded-b-lg border border-gray-300 font-sans text-white text-xl md:text-2xl p-4'>{pensionFormatted}</div>
        <div className='text-wrap font-light font-serif leading-snug tracking-tight bg-slate-50 bg-gradient-to-b'>8% of your salary after tax can be</div><div className='text-wrap font-light font-serif leading-snug tracking-tight bg-slate-50 bg-gradient-to-b'>contributed towards your pension.</div>
        {/* <div className='text-wrap font-light font-serif leading-snug tracking-tight bg-slate-50 bg-gradient-to-b'>Based on your extra income, you can</div><div className='text-wrap font-light font-serif leading-snug tracking-tight bg-slate-50 bg-gradient-to-b'>add to your pension fund as voluntary contribution.</div> */}
      </div>
    </div>
  );
};

export default FinanceCard;
