const formatSalary = (value) => {
  return value ? '₦' + FormatMoney(value) : '₦0';
};

function FormatMoney(value) {
    // Convert the value to a number and ensure it's a valid number
    const numValue = parseFloat(value);
  
    if (!isNaN(numValue)) {
      // Use toLocaleString() with options to format as money
      return numValue.toLocaleString('en-US', {
        // style: 'currency',
        // currency: 'NGN', // Nigerian Naira currency
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
    }
  
    // Return an empty string or default value if the input value is not a valid number
    return '';
  }

  export { FormatMoney as default, formatSalary };