import React from "react";
import Header from "./Header";
import Main from "./budget/main";
import FooterWithSitemap from "./Footer";

export default function Budget () {
    return(
        <>
        <Header />
        <Main />
        </>
    );
}

