import "../App.css"
import Header from "../components/Header";
import Main from "../components/Main";
import Title from "../components/Title"


function TopPage() {
    return(
        <div className="App">
            <Header />
            <div className="mt-24">
            <Title />
            </div>
            <Main />
        </div>
    )
}

export default TopPage;