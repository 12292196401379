// import { step } from "@material-tailwind/react";
import { useState, useEffect } from 'react';

const StepperControl1 = ({handleClick, currentStep, steps}) => {
    const [count, setCount] = useState(1);

    const hi = (direction) => {
        
        if (direction === "back") {
            const text = count % 6 === 0 ? '' : 'back';
            if (count > 1)
                // if (count === 7)
                setCount(count - 1);
                handleClick(text, count);
        }else if (direction === "next") {
            const text = count % 6 === 0 ? 'summary' : 'next';
            if (count <= 6)
                setCount(count + 1);
                handleClick(text, count);
        }
        
    };

    useEffect(() => {
    }, [count]);
    
    
    return <div className="font-display container flex justify-around mt-4 mb-8">
        {/* back button*/}
        <button 
        onClick={()=>hi('back')}
        className={`bg-white text-red-900 uppercase py-2 px-4 font-display font-semibold cursor-pointer border-2
         border-red-950 hover:bg-red-900 hover:text-white transition duration-200 ease-in-out${currentStep === 1 ? "opacity-50 cursor-not-allowed" : ""}`}>Back</button>
         {/* Next button */}
         {/* {currentStep === steps.length -1 ? "Final" : "Next"} */}
         <button
         onClick={()=>hi('next')}
         className="bg-white text-red-900 uppercase py-2 px-4 font-display font-semibold cursor-pointer border-2
         border-red-950 hover:text-white hover:bg-red-900 transition duration-200 ease-in-out">{currentStep === steps.length - 1
            ? 'Final'
            : currentStep === steps.length
            ? 'Done'
            : 'Next'}
          
         </button>
    </div>
}

export default StepperControl1;
