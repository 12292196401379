import React, { useState, useContext } from "react";
import GlobalContext, { SET_FORM_VALUES } from "../GlobalProvider";
import { Slider } from "@material-tailwind/react";
import { formatSalary } from "./FormatMoney";

function StepTwo({ currentStep, setCurrentStep }) {
  const { state, dispatch } = useContext(GlobalContext);
  const { formValues } = state;

  const handleSalaryChange = (value) => {
    let newValue = parseInt(value);
    if (newValue < 10) {
      newValue = 10;
    }
    setSalary(newValue);
    dispatch({ type: SET_FORM_VALUES, payload: { salary: newValue } });
  };

  const handleSalaryTextInputChange = (e) => {
    const value = parseFloat(e.target.value);
    dispatch({ type: SET_FORM_VALUES, payload: { salary: value } });
  };

  const [salary, setSalary] = useState(formValues.salary);

  return (
    <div className="flex flex-col items-center">
      <div className="text-center font-display mb-2 mt-10 text-zinc-700 font-bold text-sm">
        <p>What is your monthly income?</p>
      </div>
      <div className="flex flex-row justify-between gap-4 items-center w-full max-w-md">
        <p className="w-1/12 text-center">₦10</p>
        <div className="w-10/12">
          <Slider
            className="w-full mt-4 mb-2"
            color="#900000"
            size="sm"
            min={0}
            step={1}
            max={10000000}
            value={salary}
            onChange={(value) => handleSalaryChange(value)}
          />
        </div>
        <p className="w-1/12 text-center">₦10m</p>
      </div>
      <div className="text-center mt-10 mb-4 w-full max-w-md">
        <input
          type="text"
          placeholder={formatSalary(salary)}
          value={salary}
          className="text-center text-red-900 font-semibold h-10 bg-gray-300 w-full"
          onChange={handleSalaryTextInputChange}
        />
      </div>
    </div>
  );
}

export default StepTwo;
