import React, { useState, useContext, useEffect } from "react";
import GlobalContext, { SET_FORM_VALUES } from "../GlobalProvider";
import { Slider } from "@material-tailwind/react";
import FormatMoney, { formatSalary } from "./FormatMoney";

function StepFour({ currentStep, setCurrentStep }) {
  const { state, dispatch } = useContext(GlobalContext);
  const { formValues } = state;

  const handleRsaChange = (e) => {
    const updatedValue = parseFloat(e.target.value); // Extract the value from the event
    // Update the formvalues state with the extracted value
    setRsa(updatedValue);
    // setFormvalues({...formvalues, rsa: updatedValue});
    dispatch({
      type: SET_FORM_VALUES,
      payload: { ...formValues, rsa: updatedValue },
    });
  };

  const handleRsaTextInputChange = (e) => {
    const value = parseFloat(e.target.value);
    dispatch({
      type: SET_FORM_VALUES,
      // payload: { retirement: newRetirementAge },
      payload: { rsa: value },
    });
  };

  const handleAvcChange = (e) => {
    const updatedValue = parseFloat(e.target.value);
    setAvc(updatedValue);
    // setFormvalues({...formvalues, avc: updatedValue})
    dispatch({
      type: SET_FORM_VALUES,
      payload: { ...formValues, avc: updatedValue },
    });
  };

  const handleAvcTextInputChange = (e) => {
    const value = parseFloat(e.target.value);
    dispatch({
      type: SET_FORM_VALUES,
      // payload: { retirement: newRetirementAge },
      payload: { avc: value },
    });
  };

  const [rsa, setRsa] = useState(formValues.rsa);
  const [avc, setAvc] = useState(formValues.avc);

  return (
    <div>
      <div className="text-center font-display mb-2 text-zinc-700 font-bold text-sm">
        <p>What is your Retirement Savings Account balance?</p>
      </div>
      <div className="flex flex-row justify-between items-center ">
        <div className="w-1/12">
          <p>₦0</p>
        </div>
        <div className="w-11/12">
          <Slider
            className="flex "
            color="red"
            size="sm"
            min={0}
            step={1}
            max={500000000}
            defaultValue={formValues.rsa}
            value={formValues.rsa}
            onChange={handleRsaChange}
          />
        </div>
        <div className="w-1/12">
          <p>₦500m</p>
        </div>
      </div>
      <div className="text-center ms-4 mt-4 mb-4">
        <input
          type="text"
          placeholder={formatSalary(formValues.rsa)}
          value={formValues.rsa}
          className="text-center text-red-900 font-semibold h-12 bg-gray-300 "
          onChange={handleRsaChange}
        />
      </div>

      <div className="text-center font-display mb-2 text-zinc-700 font-bold text-sm">
        <p>What is your estimated Voluntary Contribution balance?</p>
      </div>

      <div className="flex flex-row justify-between items-center">
        <div className="w-1/12">
          <p>₦0</p>
        </div>
        <div className="w-10/12">
          <Slider
            className="flex "
            color="red"
            size="sm"
            min={0}
            step={1}
            max={500000000}
            defaultValue={formValues.avc}
            value={formValues.avc}
            onChange={handleAvcTextInputChange}
          />
        </div>
        <div className="w-1/12">
          <p>₦500m</p>
        </div>
      </div>
      <div className="text-center ms-4 mt-4 ">
        <input
          type="text"
          placeholder={formatSalary(formValues.avc)}
          value={formValues.avc}
          className="text-center text-red-900 font-semibold h-12 bg-gray-300"
          onChange={handleAvcTextInputChange}
        />
      </div>
    </div>
  );
}

export default StepFour;
