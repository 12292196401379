import React from 'react';


const InputComponent = ({ heading, strings, title, onChange }) => {


  const handleChange = (e, string)=> {
    const value = e.target.value;
    onChange(string, value);
  };

  return (
    <div>
      <h2 className='font-display text-center md:text-start text-balance font-bold leading-snug text-xl md:text-3xl my-6 md:my-10'>{heading}</h2>
      <div className="grid grid-cols-2 gap-4 md:gap-8">
        {strings.map((string, index) => (
          <input
            key={index}
            type="text"
            placeholder={string}
            className="border bg-gray-100 p-2 text-xs md:text-md font-display font-normal placeholder-gray-900"
            defaultValue={title[string]}
            onChange={(e) => handleChange(e, string)}
          />
        ))}
        
      </div>
    </div>
  );
};

export default InputComponent;
