import { useEffect } from "react";
import GlobalContext from "../GlobalProvider";
import { useContext } from "react";
import Header from "../Header";
import Container from "./Container";
import Stepper from "./Stepper";
import Chart from "./Chart";
import FormatMoney from "./FormatMoney";

function Pension() {
  const { state } = useContext(GlobalContext);

  // Format the value if the state has data
  const formattedValue_rsa = state?.data?.total_rsa_bal
    ? "₦" + FormatMoney(state.data.total_rsa_bal)
    : "₦0"; // Default value if data is null

  // Log initial state
  useEffect(() => {}, []);

  // Log state changes
  useEffect(() => {}, [state]);

  return (
    <>
      <Header />
      <div className="flex flex-col min-h-screen">
        <main className="flex-1">
          <div className="grid grid-cols-1 lg:grid-cols-2 w-full pt-10 lg:pt-28">
            <div className="w-full md:px-20">
              <Container
                PropComponent={Stepper}
                heading={"Pension Calculator"}
                // text={"Not sure about how much to save for retirement? Our Pension Calculator can help"}
              />
            </div>
            <div className="w-full md:px-32 mb-20 md:mb-0 mt-10 md:mt-0 flex justify-center items-center">
              <Container
                PropComponent={Chart}
                heading={formattedValue_rsa}
                text={"Would be your estimated RSA balance at retirement"}
              />
            </div>
          </div>
        </main>
      </div>
    </>
  );
}

export default Pension;
