import React, { useState } from 'react';
import { useBudgetContext } from '../budget/BudgetProvider';

import Header from '../../components/Header'
import MultiStepper from '../calculator/MultiStepper';
import BudgetStepOne from './BudgetStepOne';
import BudgetStepTwo from './BudgetStepTwo';
import BudgetStepThree from './BudgetStepThree';
import BudgetStepFour from './BudgetStepFour';
import BudgetStepFive from './BudgetStepFive';
import BudgetStepSix from './BudgetStepSix';
import StepperControl1 from './StepperControl1';



const Stepper1 = ()=> {

    const [currentStep, setCurrentStep] = useState(1);

    const { state, setValue } = useBudgetContext();  
  
  
    const steps = [
      "",
      "",
      "",
      "",
      "",
      "",
    ];
  
    const displayStep = (step)=> {
      switch(step) {
        case 1:
          return <BudgetStepOne />
        case 2:
          return <BudgetStepTwo />
        case 3:
          return <BudgetStepThree />
        case 4:
          return <BudgetStepFour />
        case 5:
          return <BudgetStepFive />
        case 6:
          return <BudgetStepSix />
        default:
      }
    };
  
    const handleClick = (direction, count) => {
      
      let newStep = currentStep;
    
      if (direction === "next") {
        newStep++;
      } else if (direction === "summary") {
        setValue('formCompleted', 'completed', true);
        return; // Exit the function after setting formCompleted
      } else {
        if (count !==7) {
          newStep--;
        }
        setValue('formCompleted', 'completed', false);
      }
    
      if (newStep > 0 && newStep <= steps.length) {
        // Update the current step only if it's within the valid range
        setCurrentStep(newStep);
      }
    };
    
  
    return(
      <>
      <Header />
        <div className='font-display mx-auto w-full md:w-5/6 mt-28 md:mt-52'>
          <MultiStepper steps={steps} currentStep={currentStep} />
          {/* display contents */}
          <div className='my-10 p-10'>
            {displayStep(currentStep)}
          </div>
        </div>
        <div className='mt-10'>
        <StepperControl1 
          handleClick={handleClick}
          currentStep={currentStep}
          steps={steps}
        />
        </div>
      </>
      
    )
  }
  
  export default Stepper1;