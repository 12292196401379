// import { step } from "@material-tailwind/react";
import { react, useState } from "react";
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";
const StepperControl = ({ handleClick, currentStep, steps }) => {
  const [count, setCount] = useState(1);

  const hi = (direction) => {
    if (direction === "back") {
      const text = count % 5 === 0 ? "summary" : "back";
      handleClick(text);
      if (count > 1) setCount(count - 1);
    } else if (direction === "next") {
      const text = count % 5 === 0 ? "summary" : "next";
      handleClick(text);
      if (count < 6) setCount(count + 1);
    }
  };
  return (
    <div className="font-display container flex justify-between px-5 mt-4 mb-8">
      <button
        onClick={() => hi("back")}
        className={` text-red-900 uppercase py-2 px-4 font-semibold cursor-pointer bg-gray-200 gap-2
          hover:bg-red-900 hover:text-white flex justify-center items-center transition duration-200 ease-in-out${
            currentStep === 1 ? "opacity-50 cursor-not-allowed" : ""
          }`}
      >
        <IoIosArrowBack />
        <p>Back</p>
      </button>

      <button
        onClick={() => hi("next")}
        className="bg-white text-red-900 uppercase py-2 px-4 font-semibold cursor-pointer border  flex justify-center items-center gap-2
          hover:text-white hover:bg-red-900 transition duration-200 ease-in-out"
      >
        <p>
          {currentStep === steps.length - 1
            ? "Final"
            : currentStep === steps.length
            ? "Done"
            : "Next"}
        </p>
        <IoIosArrowForward />
      </button>
    </div>
  );
};

export default StepperControl;
