import React, { useContext, useEffect, useState } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import GlobalContext from "../GlobalProvider";
import { Pie } from "react-chartjs-2";
import FormatMoney from "./FormatMoney";

ChartJS.register(ArcElement, Tooltip, Legend);

function Chart() {
  const { state } = useContext(GlobalContext);
  const [selectedButton, setSelectedButton] = useState(null);
  const [display, setDisplay] = useState(state.withdrawal_type);

  useEffect(() => {
    if (state.withdrawal_type !== null) {
      setDisplay(state.withdrawal_type);
    } else {
      setDisplay("default"); // Replace 'default' with the desired initial value
    }
  }, [state.withdrawal_type]);

  const defaultFormattedValue = "₦0";

  const data = state?.data ? state.data : null;

  const { total_rsa_bal: totalRSA, lump_sum: lumpSum, monthly } = data || {};

  const formattedValue_lump_sum = lumpSum
    ? "₦" + FormatMoney(lumpSum)
    : defaultFormattedValue;
  const formattedValue_monthly = monthly
    ? "₦" + FormatMoney(monthly)
    : defaultFormattedValue;

  const dataToDisplay = data
    ? {
        labels: ["Lump Sum", "Monthly", "Total Payout"],
        datasets: [
          {
            label: "₦",
            data: [lumpSum, monthly, lumpSum + monthly],
            backgroundColor: [
              "rgba(255, 99, 132, 0.2)",
              "rgba(54, 162, 235, 0.2)",
              "rgba(255, 206, 86, 0.2)",
            ],
            borderColor: [
              "rgba(255, 99, 132, 1)",
              "rgba(54, 162, 235, 1)",
              "rgba(255, 206, 86, 1)",
            ],
            borderWidth: 1,
          },
        ],
      }
    : {
        labels: ["Red", "Blue", "Yellow"],
        datasets: [
          {
            label: "# of Votes",
            data: [12, 19, 3],
            backgroundColor: [
              "rgba(255, 99, 132, 0.2)",
              "rgba(54, 162, 235, 0.2)",
              "rgba(255, 206, 86, 0.2)",
            ],
            borderColor: [
              "rgba(255, 99, 132, 1)",
              "rgba(54, 162, 235, 1)",
              "rgba(255, 206, 86, 1)",
            ],
            borderWidth: 1,
          },
        ],
      };

  return (
    <div className="mt-0 font-display">
      <div className="p-1 bg-inherit">
        {display === true ? (
          <div className="">
            <h4 className="text-center">
              You would be eligible for an estimated monthly pension of
            </h4>
            <h4 className="mb-2 text-white bg-red-900 mt-4 text-sm font-bold p-8 ">
              <big className="px-1">{formattedValue_monthly}</big>
            </h4>
            <h6 className="text-gray-500 p-3 text-sm">
              For further guidance or enquiries, kindly speak with any of our
              Advisors. Call Tomi at 0201 2713800-4 or Chidiogo at 0201 2714605.
              You can also reach us via email at info@crusaderpensions.com
            </h6>
          </div>
        ) : display === false ? (
          <>
            <div className="">
              <h4 className="text-center">
                You will receive an estimated lump sum of
              </h4>
              <h4 className="mb-2 text-white bg-red-900 mt-4 text-sm font-bold p-8 rounded-tr-3xl rounded-bl-3xl rounded-tl-md rounded-br-md">
                <big className=" px-1">{formattedValue_lump_sum}</big>
              </h4>
              <h6 className="text-gray-500 p-3 text-sm">
                For further guidance or enquiries, kindly speak with any of our
                Advisors. Call Tomi at 0201 2713800-4 or Chidiogo at 0201
                2714605. You can also reach us via email at
                info@crusaderpensions.com
              </h6>
            </div>
            {/* <Pie data={dataToDisplay} /> */}
          </>
        ) : (
          <h3 className="text-white text-center bg-red-900 mt-4 text-sm font-bold p-8">
            To see our recommendations, please provide values in the
            Contribution or Savings tabs, and state your retirement goal in the
            Goals tab.
          </h3>
        )}
      </div>
    </div>
  );
}

export default Chart;
