import React from 'react';
import InputComponent from './InputComponent';
import BudgetContainer from './budgetContainer';
import FinanceCard from './FinanceCard';
import { useBudgetContext } from '../budget/BudgetProvider';


const BudgetStepFour = () => {
  const { state, setErrors, setValue, clearErrors } = useBudgetContext();
  const { errors, livingCostValues } = state;

  const handleDismiss = () => {
      clearErrors();
  };
  
  const handleLivingCostChange = (key, value) => {
    try {
        if (!Number.isNaN(Number(value))) {
            setValue('livingCostValues', key, value);
        } else {
            throw new Error('input must be a valid number.');
        }
    } catch (error) {
        const msg = error.message;
        setErrors(key, msg);
    }
  };

  const heading = 'Living Cost';

  
  return (
    <>
        {Object.values(errors).some(error => error) && (
            <div className="font-display bg-red-100 border-red-400 text-red-700 px-4 py-3 rounded-lg text-sm shadow-md mb-2">
                <button className="ml-4 text-black hover:text-red-600 px-2 py-1 float-end" onClick={handleDismiss}>
                    dismiss
                </button>
                {Object.entries(errors).map(([key, error]) => (
                    <div key={key}>
                        {key} {error}
                    </div>
                ))}
                
            </div>
        )}
        <div className='flex flex-col md:flex-row justify-between m-0 p-0'>
            <div className='w-full md:w-2/4 m-0'>
                <BudgetContainer
                    PropComponent={InputComponent}
                    componentProps={{ heading, strings: Object.keys(livingCostValues), title:livingCostValues }}
                    onChange={handleLivingCostChange}
                />
            </div>
            <div className='sm:m-4 md:m-0 border-green-600'>
                <BudgetContainer
                    PropComponent={FinanceCard}
                    componentProps={{}}
                />
            </div>
        </div>
    </>
);
};

export default BudgetStepFour;
