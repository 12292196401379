import React from 'react';
import { Link } from 'react-router-dom';
import HomeImage from '../public/Home.jpg'; 
import BizWoman from '../public/bizWoman.jpg'; 

function Title(params) {
  return (
    <div className="font-display min-h-screen flex items-center justify-center">
      <div className="w-full sm:m-14 sm:max-w-screen-sm md:max-w-screen-md lg:max-w-screen-lg xl:max-w-screen-xl p-4">
        <h1 className="text-4xl sm:text-5xl md:text-6xl lg:text-7xl xl:text-8xl text-center text-red-910">
          Pension Calculator
        </h1>
        <div className="sm:max-w-sm w-full sm:max-h-full lg:max-w-3xl lg:max-h-full lg:flex sm:rounded-full md:rounded-none lg:rounded-none m-auto mt-10">
          <div className="h-48 lg:h-auto lg:w-48 flex-none bg-cover rounded-tl-3xl rounded-br-3xl sm:rounded-none lg:rounded-none lg:rounded-l-lg text-center overflow-hidden"
            style={{ backgroundImage: `url(${HomeImage})` }}
            title="Crusader sterling client">
          </div>
          <div className="sm:border-none md:border lg:border xl:border border-gray-400 bg-white rounded-b lg:rounded-b-none lg:rounded-r p-4 flex flex-col justify-between leading-normal">
            <div className="mb-8">
              <p className="text-sm text-gray-600 flex items-center">
                <svg className="fill-current text-gray-500 w-3 h-3 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                  <path d="M4 8V6a6 6 0 1 1 12 0v2h1a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-8c0-1.1.9-2 2-2h1zm5 6.73V17h2v-2.27a2 2 0 1 0-2 0zM7 6v2h6V6a3 3 0 0 0-6 0z" />
                </svg>
                Customers only
              </p>
              <div className="font-bold text-xl text-justify mb-2 text-red-910">Turn your Retirement Dreams into Reality</div>
              <p className="text-gray-700 text-justify sm:text-left lg:text-left xl:text-start text-base">
                <p className='pb-2'>Discover Your Dream Retirement: Uncover the Power of Your Savings!</p>
                <p className='pb-2'>Did you know your retirement funds can pave the way for the life you've always wanted after retirement? Our cutting-edge pension calculator reveals a sneak peek into your retirement paradise. Share your aspirations, and we will unlock the roadmap to make them a reality.</p>
                <p className='pt-2'>Get instant, personalised guidance on achieving your retirement dreams and even connect directly with a Wealth Advisor for tailored strategies to boost your financial well-being. Your dream retirement is just a click away!</p>
                </p>
            </div>
            <div className="flex flex-col sm:max-h-max max-h-max">
              <div className='flex items-center mb-4 sm:mb-2'>
                  {/* <img className="w-10 h-10 rounded-full mr-4" src={ BizWoman } alt="Avatar of Jonathan Reinink" />
                  <div className="text-sm max-h-screen">
                      <p className="text-gray-900 leading-none">Dorcas jeffery</p>
                      <p className="text-gray-600">Aug 18</p>
                  </div> */}
              </div>
              <div className=' flex flex-row gap-4'>
                <Link to="/start">
                    <button className="bg-white mt-2 text-red-900 font-serif text-lg md:text-xl lg:text-xl py-2 md:py-4 px-6 md:px-8 border-2 mb-0 md:mb-0 border-red-900 transform hover:bg-red-100">
                    Click here to get started
                    </button>
                </Link>
                {/* <Link to="/budget">
                    <button className="bg-red-900 mt-2 text-white font-serif text-lg md:text-xl py-1 md:py-2 px-4 md:px-6 border-2 border-red-800 transform hover:bg-red-800">
                    Try our budget Calculator
                    </button>
                </Link> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Title;
