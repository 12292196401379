import { React, useEffect, useState, useRef } from "react";
import { IoIosCheckmark } from "react-icons/io";

const MultiStepper = ({ steps, currentStep }) => {
  const [newStep, setNewStep] = useState([]);

  const stepRef = useRef();

  const updateStep = (stepNumber, steps) => {
    const newSteps = [...steps];
    let count = 0;
    while (count < newSteps.length) {
      // current step
      if (count === stepNumber) {
        newSteps[count] = {
          ...newSteps[count],
          highlighted: true,
          selected: true,
          completed: true,
        };
        count++;
      }
      // step completed
      else if (count < stepNumber) {
        newSteps[count] = {
          ...newSteps[count],
          highlighted: true,
          selected: false,
          completed: true,
        };
        count++;
      }
      // step pending
      else {
        newSteps[count] = {
          ...newSteps[count],
          highlighted: false,
          selected: false,
          completed: false,
        };
        count++;
      }
    }
    return newSteps;
  };
  useEffect(() => {
    const stepsState = steps.map((step, index) =>
      Object.assign(
        {},
        {
          description: step,
          completed: false,
          highlighted: index === 0 ? true : false,
          selected: index === 0 ? true : false,
        }
      )
    );
    stepRef.current = stepsState;
    const current = updateStep(currentStep - 1, stepRef.current);
    setNewStep(current);
  }, [steps, currentStep]);

  const displaySteps = newStep.map((step, index) => {
    return (
      <div
        key={index}
        className={
          index !== newStep.length - 1
            ? "font-display w-full flex items-center"
            : "flex items-center"
        }
      >
        <div className="font-display relative flex flex-col items-center ">
          <div
            className={`font-display rounded-full transition duration-1000 ease-in-out border-2 border-gray-200 h-7 w-7 flex items-center justify-center py-2 ${
              step.selected
                ? `bg-red-900 text-white text-sm font-display`
                : `bg-gray-400 text-xs text-white`
            }`}
          >
            {/*Display number */}
            {step.completed ? <IoIosCheckmark /> : index + 1}
          </div>
          <div
            className={`absolute top-0 text-center mt-10 w-32 text-xs font-display font-medium uppercase ${
              step.selected ? `text-red-900` : `text-gray-300`
            }`}
          >
            {/* Display descriptions */}
            {step.selected && step.description}
          </div>
        </div>
        <div
          className={`font-display flex-auto border-t transition duration-500 ease-in-out ${
            step.completed ? `border-red-950` : `border-gray-300`
          }`}
        >
          {/*Display line */}
        </div>
      </div>
    );
  });
  return (
    <div className="font-display mx-4 p-4 flex justify-between items-center">
      {displaySteps}
    </div>
  );
};
export default MultiStepper;
