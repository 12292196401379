
import React, { createContext, useReducer } from 'react';

const initialState = {
  data: null,
  formValues: {
    salary: null,
    monthly: null,
    current: 18,
    retirement: 35,
    rsa: 0,
    avc: 0,
  }, // Added to store form values
  withdrawal_type: 2,
  // withdrawal_type: null,
};

const GlobalContext = createContext(initialState);
export const SET_DATA = 'SET_DATA';
export const SET_FORM_VALUES = 'SET_FORM_VALUES'; // Action type for form values
export const UPDATE_WITHDRAWAL_TYPE = 'UPDATE_WITHDRAWAL_TYPE'; // New action type for updating withdrawal type

const reducer = (state, action) => {
  switch (action.type) {
    case SET_DATA:
      return {
        ...state,
        data: action.payload,
      };
    case SET_FORM_VALUES:
      return {
        ...state,
        formValues: { ...state.formValues, ...action.payload },
    };
    case UPDATE_WITHDRAWAL_TYPE: // Handle the new action type
    return {
      ...state,
      withdrawal_type: action.payload,
    };
    default:
      return state;
  }
};

export const GlobalProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <GlobalContext.Provider value={{ state, dispatch }}>
      {children}
    </GlobalContext.Provider>
  );
};

// export default GlobalContext;
export { GlobalContext as default}