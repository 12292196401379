function BudgetContainer({ PropComponent, componentProps, onChange }) {
    return (
      <div className='font-display sm:w-full mx-auto mt-auto flex items-center justify-center pb-2 text-center'>
        <div className='md:w-full space-x-2 mx-auto pb-2 bg-white'>
          <div className='container horizontal' >
            <div>
              <PropComponent {...componentProps} onChange={onChange} />
            </div>
          </div>
        </div>
      </div>
    );
  }
  
  export default BudgetContainer;