import { useState, useContext } from "react";
import { Slider } from "@material-tailwind/react";
import GlobalContext, { UPDATE_WITHDRAWAL_TYPE } from "../GlobalProvider";

function StepFive({ currentStep, setCurrentStep, formvalues, setFormvalues }) {
  const { state, dispatch } = useContext(GlobalContext);
  const { withdrawal_type } = state;

  const [selectedButton, setSelectedButton] = useState(
    withdrawal_type === false ? 0 : 1
  );

  const handleClick = (button) => {
    dispatch({
      type: UPDATE_WITHDRAWAL_TYPE,
      payload: button === 0 ? false : true,
    });
  };

  return (
    <div>
      <div className="text-center font-display font-medium mb-5">
        <p>Select a type of goal?</p>
      </div>
      <div className="flex justify-center gap-5">
        <button
          className={`${
            selectedButton === 0
              ? "bg-red-900 hover:text-gray-500"
              : "bg-red-950 text-gray-100"
          } font-semibold py-2 px-4 w-[200px] text-white`}
          onClick={() => {
            handleClick(0);
            setSelectedButton(1); // Change selectedButton to the other button
          }}
        >
          {"Lump Sum"}
        </button>
        <button
          className={`${
            selectedButton === 1
              ? "bg-red-900 hover:text-gray-500"
              : "bg-gray-500 text-gray-100"
          }  font-semibold py-2 px-4 w-[200px] text-white`}
          onClick={() => {
            handleClick(1);
            setSelectedButton(0); // Change selectedButton to the other button
          }}
        >
          Program Withdrawal
        </button>
      </div>
      {/* <div className="font-display text-center font-medium mt-5 mb-3">
            <p>What is your desired lump sum at retirement?</p>
        </div>
        <div className="flex flex-row justify-between items-center">
            <div className="w-1/12">
                <p>₦0</p>
            </div>
            <div className="w-11/12">
                <Slider className='flex mt-4 mb-2' color="red" size="sm" defaultValue={0}  min={0}  max={2000000}/>
            </div>
            <div className="w-1/12">
                <p>₦100m</p>
            </div>
        </div> */}
    </div>
  );
}

export default StepFive;
