import { IconContext } from "react-icons";
import { FaTwitterSquare, FaInstagramSquare, FaFacebookSquare, FaYoutubeSquare, FaLinkedinIn } from "react-icons/fa";


function IconTray() {
  return (
    <div className="flex items-center justify-center space-x-4">
      <IconContext.Provider value={{ color: "red-900" }}>
        <div className="bg-white p-2 rounded-full">
          <FaFacebookSquare />
        </div>
      </IconContext.Provider>
      <IconContext.Provider value={{ color: "red-900" }}>
        <div className="bg-white p-2 rounded-full">
          <FaTwitterSquare />
        </div>
      </IconContext.Provider>
      <IconContext.Provider value={{ color: "red-900" }}>
        <div className="bg-white p-2 rounded-full">
          <FaLinkedinIn />
        </div>
      </IconContext.Provider>
      <IconContext.Provider value={{ color: "red-900" }}>
        <div className="bg-white p-2 rounded-full">
          <FaInstagramSquare />
        </div>
      </IconContext.Provider>
      <IconContext.Provider value={{ color: "red-900" }}>
        <div className="bg-white p-2 rounded-full">
          <FaYoutubeSquare />
        </div>
      </IconContext.Provider>
    </div>
  );
}

export default IconTray;
