  import React, { useContext, useReducer, createContext } from "react";

  const initialState = {
    errors: {},
    incomeValues: {
      'Salary (after tax)': '',
      'Income from self employment': '',
      'Income from side hustle': '',
      'Additional Sources': ''
    },
    houseHoldValues: {
      'House Rent / Rent savings': '',
      'Mobile Data': '',
      'Utilities (electricity, gen, gas, waste, etc)': '',
      'Additional Item': ''
    },
    livingCostValues: {
      'Food stuff': '',
      'Beverages': '',
      'Eatery / Takeaways': '',
      'Children’s clothes & shoes': '',
      'Work & party clothes': '',
      'Dry cleaning': '',
      'Beauty treatments / Hairdressing': '',
      'Medicines': '',
      'Toiletries': '',
      'Additional Item': ''
    },
    financeValues: {
      'Life assurance': '',
      'Insurance (car, health, etc)': '',
      'Regular savings': '',
      'Emergency savings': '',
      'Shares & Investment': '',
      'Legal fees / Financial Advisor': '',
      'Additional Item': ''
    },
    familyFriendsValues: {
      'Baby care & items': '',
      'Social clubs': '',
      'School fees (books, trips, extra lessons)': '',
      'Support for relatives & friends': '',
      'Pet care': '',
      'Donation & Sponsorships': '',
      'Vacation & Outing': '',
      'Special Holidays': '',
      'Birthdays / Family celebrations': '',
      'Additional Item': ''
    },
    travelTransportValues: {
      'Petrol or Diesel': '',
      'Car loan repayment': '',
      'Maintenance & repairs': '',
      'Public transportation fares': '',
      'Air travel': '',
      'Additional Item': ''
    },
    formCompleted: {
      'completed': false,
    },
  };

  const BudgetContext = createContext();

  // export const useBudgetContext = () => useContext(BudgetContext);

  export const useBudgetContext = () => {
    const context = useContext(BudgetContext);
    if (!context) {
      throw new Error("useBudgetContext must be used within a BudgetProvider");
    }
    return context;
  };

  const SET_ERRORS = 'SET_ERRORS';
  const SET_VALUE = 'SET_VALUE';
  const CLEAR_ERRORS = 'CLEAR_ERRORS';


  const reducer = (state, action) => {
    switch (action.type) {
      case SET_ERRORS:
      const { errkey, message } = action.payload;
      return {
        ...state,
        errors: {
          ...state.errors,
          [errkey]: message
        }
      };
      case CLEAR_ERRORS:
        return { ...state, errors: {} };

      case SET_VALUE:
        const { category, key, value } = action.payload;
        return {
          ...state, [category]: {...state[category], [key]: value }
        };
      default:
        return state;
    }
  };

  export const BudgetProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    const setErrors = (errkey, message) => {
      dispatch({ type: SET_ERRORS, payload: { errkey, message } });
    };

    const setValue = (category, key, value) => {
      dispatch({ type: SET_VALUE, payload: { category, key, value } });
    };

    const clearErrors = () => {
      dispatch({ type: CLEAR_ERRORS });
    };
    

    return (
      <BudgetContext.Provider value={{ state, setErrors, setValue, clearErrors }}>
        {children}
      </BudgetContext.Provider>
    );
  };

