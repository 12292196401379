const BudgetStepSummary = () => {
    return (
        <>
            <div className="font-display text-lg md:text-xl text-center lg:text-3xl flex flex-col">
                <div className=" flex-1">
                    <h1 className="text-wrap font-serif font-bold">Would you like to learn how your disposable income can boost your retirement benefits?</h1>
                </div>
                <div className="flex justify-around">
                    <a href="https://crusaderpensions.com/">
                    <button className="px-4 md:px-6 py-1 md:py-2 rounded md:rounded-md my-10 text-sm md:text-lg font-display font-light md:font-medium lg:text-xl bg-gray-400 text-white">Maybe later</button>
                    </a>
                    <a href="https://pension-calc.vercel.app/start">
                    <button className="px-8 md:px-12 py-1 md:py-2 rounded md:rounded-md my-10 text-sm md:text-lg font-display font-light md:font-medium sm:text-md lg:text-xl bg-red-900 text-white ">Let's go</button>
                    </a>
                </div>
            </div>
            <div className="font-display flex flex-col mt-6">
                <div className="text-sm md:text-2xl lg:text-4xl mt-4 mb-4">
                    <h1 className=" font-bold">Switch up to value</h1>
                </div>
                <div className="text-xs sm:text-sm md:text-md">
                    <p className=" font-display font-extralight">Get high returns on your pension savings and join the leagues of the</p>
                    <p className="font-bold">Most Valuable Pensioners.</p>
                </div>
                <div className="w-full justify-center flex">
                    <a href="https://registration.crusaderpensions.com/">
                    <button className="bg-red-900 hover:bg-red-950 shadow-red-900 shadow-md hover:shadow-red-950 hover:shadow-lg  text-white px-6 md:px-8 py-1 md:py-2 rounded md:rounded-md my-10 text-sm md:text-lg font-display font-light md:font-medium">
                        Get Started
                    </button>
                    </a>
                </div>
            </div>

        </>
    );
}

export default BudgetStepSummary;