import React, { useContext, useState, useEffect } from "react";
import GlobalContext, { SET_FORM_VALUES, SET_DATA } from "../GlobalProvider";
import StepThree from "./StepThree";
import StepFour from "./StepFour";
import StepFive from "./StepFive";
import StepOne from "./StepOne";
import StepTwo from "./StepTwo";
import axios from "axios";
import MultiStepper from "./MultiStepper";
import StepperControl from "./StepperControl";

const Stepper = () => {
  const BASE_URL = "https://webservices.crusaderpensions.com:1166/api";

  const [currentStep, setCurrentStep] = useState(1);
  const [responseData, setResponseData] = useState(null);

  const { state, dispatch } = useContext(GlobalContext);
  const { formValues } = state;

  const steps = ["profile", "income", "contributions", "savings", "goal"];

  const displayStep = (step) => {
    switch (step) {
      case 1:
        return <StepOne />;
      case 2:
        return <StepTwo />;
      case 3:
        return <StepThree />;
      case 4:
        return <StepFour />;
      case 5:
        return <StepFive />;
      default:
    }
  };

  const fetchData = async () => {
    const updatedFormValues = {
      ...formValues,
      salary: formValues.salary,
      monthly: formValues.monthly,
    };

    if (
      updatedFormValues.monthly !== null &&
      updatedFormValues.rsa !== null &&
      updatedFormValues.avc !== null
    ) {
      try {
        console.log("Calling my api");
        const response = await axios.post(
          BASE_URL + "/calculate",
          updatedFormValues
        );
        const { data } = response;
        console.log("Got data: ", data);

        if (data && data.success && data.data && data.data.length > 0) {
          const [totalRSA, [lumpSum, monthly]] = data.data[0];

          const transformedData = {
            total_rsa_bal: totalRSA,
            lump_sum: lumpSum,
            monthly: monthly,
          };
          setResponseData(transformedData);
          dispatch({ type: SET_DATA, payload: transformedData });
        } else {
        }
      } catch (error) {}
      return responseData;
    }
  };

  const updateGlobalFormValues = () => {
    fetchData();
  };

  const handleClick = (direction) => {
    let newStep = currentStep;
    direction === "next" ? newStep++ : newStep--;

    newStep > 0 && newStep <= steps.length && setCurrentStep(newStep);
  };

  useEffect(() => {
    updateGlobalFormValues();
  }, [formValues.monthly, formValues.avc, formValues.rsa]);

  useEffect(() => {
    dispatch({ type: SET_FORM_VALUES, payload: formValues });
  }, []);

  return (
    <>
      <div className="mt-10">
        <MultiStepper steps={steps} currentStep={currentStep} />
        <div className="pt-10 px-10">{displayStep(currentStep)}</div>
      </div>
      <div className="mt-10">
        <StepperControl
          handleClick={handleClick}
          currentStep={currentStep}
          steps={steps}
        />
      </div>
    </>
  );
};

export default Stepper;
