import React, { useState, useContext, useEffect } from "react";
import { FaFemale, FaMale } from "react-icons/fa";
import { IconContext } from "react-icons";
import { Slider } from "@material-tailwind/react";
import GlobalContext, { SET_FORM_VALUES } from "../GlobalProvider";
import "../../male-icons.css"; // Import default male icons style
import "../../female-icons.css"; // Import default female icons style

function StepOne({ currentStep, setCurrentStep }) {
  const { state, dispatch } = useContext(GlobalContext);
  const { formValues } = state;

  const [maleSelected, setMaleSelected] = useState(false);
  const [femaleSelected, setFemaleSelected] = useState(false);

  const handleMaleClick = () => {
    setMaleSelected(true);
    setFemaleSelected(false);
  };

  const handleFemaleClick = () => {
    setMaleSelected(false);
    setFemaleSelected(true);
  };

  const handleCurrentAgeChange = (e) => {
    let updatedValue = parseInt(e.target.value);
    let retirementVal = formValues.retirement;
    if (updatedValue < 18) {
      updatedValue = 18;
    } else {
      updatedValue = Math.min(updatedValue, retirementVal);
    }
    setCurrentAge(updatedValue);
    dispatch({ type: SET_FORM_VALUES, payload: { current: updatedValue } });
  };

  const handleRetirementAgeChange = (e) => {
    const value = e.target.value;
    const updatedValue = Math.max(parseInt(value), formValues.current);

    dispatch({
      type: SET_FORM_VALUES,
      payload: { retirement: updatedValue },
    });
  };

  const handleCurrentTextInputChange = (e) => {
    const updatedValue = parseInt(e.target.value);
    if (
      !isNaN(updatedValue) &&
      updatedValue >= 18 &&
      updatedValue <= formValues.retirement
    ) {
      setCurrentAge(updatedValue);
      dispatch({ type: SET_FORM_VALUES, payload: { current: updatedValue } });
    }
  };

  const handleRetirementTextInputChange = (e) => {
    const value = e.target.value;

    if (
      !isNaN(value) &&
      parseInt(value) >= formValues.current &&
      parseInt(value) <= 100
    ) {
      const updatedValue = Math.max(parseInt(value), formValues.current);
      dispatch({
        type: SET_FORM_VALUES,
        payload: { retirement: updatedValue },
      });
    }
  };

  const [retirement, setRetirementAge] = useState(formValues.retirement);
  const [retireInput, setRetireInput] = useState(0);
  const [current, setCurrentAge] = useState(formValues.current);

  // Scroll to top when component mounts
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="grid grid-cols-2 gap-10 border-none pb-2 ">
        <div className=" ">
          <p className=" text-zinc-500 text-sm">Gender</p>
        </div>
        <div className="flex justify-around items-center gap-10">
          <div
            onClick={handleMaleClick}
            style={{
              cursor: "pointer",
              color: maleSelected ? "#900000" : "gray",
              fontWeight: "bold",
            }}
            className="flex justify-around items-center"
          >
            <IconContext.Provider
              value={{
                className: "male-icons",
                style: {
                  color: maleSelected ? "#900000" : "gray",
                  fontWeight: "800",
                  textAlign: "center",
                },
              }}
            >
              <FaMale size={20} />
            </IconContext.Provider>
            <p className="hidden md:block font-light text-center text-xs">
              Male
            </p>
          </div>
          <div
            onClick={handleFemaleClick}
            style={{
              cursor: "pointer",
              color: femaleSelected ? "#900000" : "gray",
              fontWeight: "bold",
            }}
            className="flex justify-around items-center"
          >
            <IconContext.Provider
              value={{
                className: "female-icons",
                style: {
                  color: femaleSelected ? "#900000" : "gray",
                  fontWeight: "800",
                },
              }}
            >
              <FaFemale />
            </IconContext.Provider>
            <p className="hidden md:block font-light text-xs">Female</p>
          </div>
        </div>
      </div>
      <hr className="my-0 mx-auto w-full  p-0" />
      <br className="h-4" />
      <div className="flex flex-col gap-4">
        <div className="w-full">
          <div className="flex justify-between items-center">
            <h4 className=" text-zinc-500 text-sm">Retirement Age</h4>
            <div className="rounded-full h-10 w-10 bg-red-900 border border-red-300 flex items-center text-white">
              <p className="text-center w-full font-bold">
                {formValues.retirement}
              </p>
            </div>
          </div>
          <Slider
            className="flex mt-4 mb-2 h-2"
            color="red"
            size="xs"
            min={0}
            max={100}
            defaultValue={formValues.retirement}
            value={formValues.retirement}
            onChange={handleRetirementAgeChange}
          />
        </div>

        <div className="w-full">
          <div className="flex justify-between items-center">
            <h4 className=" text-zinc-500 text-sm">Current Age</h4>
            <div className="rounded-full h-10 w-10 bg-red-900 border border-red-300 flex items-center text-white">
              <p className="text-center w-full font-bold">
                {formValues.current}
              </p>
            </div>
          </div>
          <Slider
            className="flex mt-4 mb-2"
            color="red"
            size="sm"
            min={0}
            max={100}
            defaultValue={formValues.current}
            value={formValues.current}
            onChange={handleCurrentAgeChange}
          />
        </div>
      </div>
    </>
  );
}

export default StepOne;
